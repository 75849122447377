import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

// MSAL imports
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { iamApiRequest } from "./authConfig";


import { TopNav } from 'govuk-react';
import { Footer } from 'govuk-react';
import { SectionBreak } from 'govuk-react';

import OathToken from './components/OathToken';

class App extends Component {

  authRequest = {
    ...iamApiRequest
  };

  render() {
    return (
      <MsalProvider instance={this.props.pca}>
        <div>
          <Router>
            <MsalAuthenticationTemplate
              interactionType={InteractionType.Redirect}
              authenticationRequest={this.authRequest}
            >
              <TopNav
                serviceTitle={<TopNav.NavLink href="." target="new">MoJ MFA Token Activation</TopNav.NavLink>}
              />
              <div className="container">
                <Switch>
                  <Route path="/" exact component={OathToken} />
                  <Route path="/oathtokens" component={OathToken} />
                </Switch>
              </div>

            </MsalAuthenticationTemplate>
          </Router>
        </div>
        <SectionBreak
          level="XLARGE"
        />
        <Footer
          copyright={{
            image: {
              height: 102,
              src: 'static/media/govuk-crest.05968778.png',
              width: 125
            },
            link: 'https://www.nationalarchives.gov.uk/information-management/re-using-public-sector-information/uk-government-licensing-framework/crown-copyright/',
            text: 'Crown copyright'
          }}
        />
      </MsalProvider>
    );
  }
}

export default App;
