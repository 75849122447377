import React, { Component } from 'react'
import Alert from 'react-bootstrap/Alert';

class Message extends Component {
    render() {
        return (
            <Alert variant={this.props.message.level} show={this.props.message.show} onClose={() => this.props.hideMessage()} dismissible>
                <Alert.Heading>{this.props.message.title}</Alert.Heading>
                <p>{this.props.message.text}</p>
            </Alert>
        )
    }
}

export default Message