// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
        authority: process.env.REACT_APP_MSAL_AUTHORITY,
        redirectUri: document.getElementById('root').baseURI,
        postLogoutRedirectUri: document.getElementById('root').baseURI
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"]
};

export const iamApiRequest = {
    scopes: [process.env.REACT_APP_IAM_API_SCOPE]
};