import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const OathTokenActivate = (props) => {
    return (
        <Modal show={props.activating} onHide={props.hideActivate}>
            <Modal.Header closeButton>
                <Modal.Title>Activate Token</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p/>
                <Form inline>
                    <Form.Control name="verificationCode" type="text" value={props.verificationCode} onChange={props.handleInputChange} className="mr-sm-2"/>
                    <Button type="submit" onClick={props.verifyButton_Click}>Verify</Button>
                </Form>
                <p/>
            </Modal.Body>
        </Modal>
    );
}

export default OathTokenActivate;