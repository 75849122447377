import React from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

const OathTokenList = (props) => {
    return (
        <Table style={{ textAlign: 'left' }} striped hover responsive="sm">
            <thead>
                <tr>
                    <th>Manufacturer</th>
                    <th>Model</th>
                    <th>Serial number</th>
                    <th>Activated</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {
                    props.oathTokenItems.length > 0 ? (
                        props.oathTokenItems.map((token) => (
                            <tr key={token.oathId}>
                                <td>{token.manufacturer}</td>
                                <td>{token.model}</td>
                                <td>{token.serialNumber}</td>
                                <td>{token.enabled ? 'Yes' : 'No'}</td>
                                <td>
                                    <Button disabled={token.enabled} variant="primary" size="sm" onClick={ () => props.activateButton_Click(token.oathId)}>Activate</Button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        props.loading ? (
                            <tr>
                                <td colSpan={5}>Loading...</td>
                            </tr>
                        ) : (
                            <tr>
                                <td colSpan={5}>No tokens were found for your user</td>
                            </tr>
                        )
                    )
                }
            </tbody>
        </Table>
    )
}

export default OathTokenList;