import axios from 'axios';

const AZURE_AD_EXPLORER_API_BASE_URL = process.env.REACT_APP_AZURE_AD_EXPLORER_API_BASE_URL;

export default class AzureAdExplorerService {

    async getMyHardwareTokens(userId, accessToken){
        const endpoint = `${AZURE_AD_EXPLORER_API_BASE_URL}/users/${userId}/authentication/hardwaretokenmethods`;
        const config = {
            headers: { Authorization: `Bearer ${accessToken}` }
        };

        return await axios.get(endpoint, config).then((response)=> {return response.data});
    }
    async activateHardwareToken(userId, oathId, verificationCode, accessToken){
        const endpoint = `${AZURE_AD_EXPLORER_API_BASE_URL}/users/${userId}/authentication/hardwaretokenmethods/${oathId}/activate`;
        await axios.post(endpoint, {
            verificationCode: verificationCode
          }, {
            headers: {
              'Authorization': `Bearer ${accessToken}` 
            }
          }
        )
    }
}