import React, { Component } from 'react';

// Msal imports
import { MsalContext } from "@azure/msal-react";
import { iamApiRequest } from "../authConfig";

import AzureAdExplorerService from '../services/AzureAdExplorerService';
//import AzureAdExplorerService from '../services/AzureAdExplorerMockService';

import Message from './Message';
import OathTokenList from './OathToken.List';
import OathTokenActivate from './OathToken.Activate';

export default class OathToken extends Component {

    static contextType = MsalContext;

    constructor(props) {
        super(props);

        this.azureAdExplorerService = new AzureAdExplorerService();

        this.state = {
            oathId: '',
            verificationCode: '',
            oathTokenItem: { },
            oathTokenItems: [],
            message: {
                show: false,
                level: 'info',
                title: '',
                text: ''
              },  
            loading: true,
            activating: false
        };

        this.displayMessage = this.displayMessage.bind(this);
        this.hideMessage = this.hideMessage.bind(this);
        this.showActivate = this.showActivate.bind(this);
        this.hideActivate = this.hideActivate.bind(this);
        this.activateOathToken = this.activateOathToken.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.activateButton_Click = this.activateButton_Click.bind(this);
        this.verifyButton_Click = this.verifyButton_Click.bind(this);
    }

    componentDidMount() {
        this.refreshOathTokenItems();
    }

    displayMessage(level, title, text){
        console.log(`${level}: ${title} - ${text}`);
        this.setState({
          message: {
            show: true, 
            level: level, 
            title: title, 
            text: text
          }
        });
      }
    
      hideMessage(){
        this.setState({
          message: {
            show: false, 
            level: 'info', 
            title: '', 
            text: ''
          }
        });
      }
      
    showActivate(){
        this.setState({
            activating: true
        })
    }

    hideActivate(){
        this.setState({
            verificationCode: '',
            activating: false
        })
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        this.setState({
            [name]: value
        });
    }

    activateButton_Click(selectedId){
        this.setState({
            oathId: selectedId,
        }); 
        this.showActivate();      
    }

    verifyButton_Click(event){
        this.setState({
            activating: false
        });  
        //this.activateOathToken(this.state.userId, this.state.oathId, this.state.verificationCode)
        this.activateOathToken(this.state.oathId, this.state.verificationCode)
        event.preventDefault();
    }

    async refreshOathTokenItems() {
        try {
            var account = this.context.accounts[0];
            if (account) {
                var response = await this.context.instance.acquireTokenSilent({
                    ...iamApiRequest,
                    account: account
                });
                
                if (response) {
                    var apiResponse = await this.azureAdExplorerService.getMyHardwareTokens(account.localAccountId, response.accessToken);
                    //this.displayMessage('info', 'Fetched list of tokens', `Fetched list of tokens for user ${account.localAccountId}`);
                    this.setState({ oathTokenItems: apiResponse });
                }
            }
        }
        catch (error) {
            this.displayMessage('danger', 'Failed to list tokens', error.message);
            this.setState({ oathTokenItems: [] });
        }
        this.setState({ loading: false })
    }

    async activateOathToken(oathId, verificationCode) {
        try {
            var account = this.context.accounts[0];
            if (account) {
                var response = await this.context.instance.acquireTokenSilent({
                    ...iamApiRequest,
                    account: account
                });
                
                if (response) {
                    await this.azureAdExplorerService.activateHardwareToken(account.localAccountId, oathId, verificationCode, response.accessToken);
                    this.displayMessage('info', 'Token activated', `The token ${oathId} was activated for user ${account.localAccountId}.`);
                    this.refreshOathTokenItems();
                }
            }
        }
        catch (error) {
            this.displayMessage('danger', 'Token activation failed', error.message);
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <Message message={this.state.message} hideMessage={this.hideMessage} />
                <OathTokenActivate
                    activating={this.state.activating}
                    verificationCode={this.state.verificationCode}
                    verifyButton_Click={this.verifyButton_Click}
                    hideActivate={this.hideActivate}
                    handleInputChange={this.handleInputChange}
                />
                <h1>Oath Tokens</h1>
                <p>These are the tokens you can use to sign into.</p>
                <br></br>
                <div className="row">
                    <OathTokenList
                        loading={this.state.loading}
                        oathTokenItems={this.state.oathTokenItems}
                        activateButton_Click={this.activateButton_Click}
                    />
                </div>
            </div>
        );
    }
}
